import { Box, FormHelperText } from "@mui/material";
import { amber } from "@mui/material/colors";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";

export function WarningMessageYellow({
  message,
  hasSpace = true,
}: {
  message: string;
  hasSpace?: boolean;
}) {
  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      mt={hasSpace ? 3 : 0}
      p={hasSpace ? 2 : 0}
    >
      <FormHelperText sx={{ bgcolor: amber[100], borderRadius: 2, p: 1 }}>
        <WarningAmberRoundedIcon sx={{ marginBottom: -0.8 }} />
        {message}
      </FormHelperText>
    </Box>
  );
}
