export const countriesWithZones = [
  // zona 5
  { name: "Aruba", globalZoneId: 5 },
  { name: "Anguilla", globalZoneId: 5 },
  { name: "Antigua and Barbuda", globalZoneId: 5 },
  { name: "British Virgin Islands", globalZoneId: 5 },
  { name: "El Salvador", globalZoneId: 5 },
  {
    name: "Caribbean Netherlands (Bonaire, Saba and Sint Eustatius)",
    globalZoneId: 5,
  },
  { name: "Guadeloupe", globalZoneId: 5 },
  { name: "Cuba", globalZoneId: 5 },
  { name: "Curaçao", globalZoneId: 5 },
  { name: "Guatemala", globalZoneId: 5 },
  { name: "Grenada", globalZoneId: 5 },
  { name: "Cayman Islands", globalZoneId: 5 },
  { name: "Costa Rica", globalZoneId: 5 },
  { name: "Jamaica", globalZoneId: 5 },
  { name: "Sint Maarten", globalZoneId: 5 },
  { name: "Saint Christopher and Nevis", globalZoneId: 5 },
  { name: "Saint Vincent", globalZoneId: 5 },
  { name: "Saint Lucia", globalZoneId: 5 },
  { name: "Turks and Caicos Islands", globalZoneId: 5 },
  { name: "Dominica", globalZoneId: 5 },
  { name: "Dominican Republic", globalZoneId: 5 },
  { name: "Trinidad and Tobago", globalZoneId: 5 },
  { name: "Nicaragua", globalZoneId: 5 },
  { name: "Haiti", globalZoneId: 5 },
  { name: "Panama", globalZoneId: 5 },
  { name: "Bahamas", globalZoneId: 5 },
  { name: "Bermuda Islands", globalZoneId: 5 },
  { name: "Barbados", globalZoneId: 5 },
  { name: "Belize", globalZoneId: 5 },
  { name: "Honduras", globalZoneId: 5 },
  { name: "Martinique", globalZoneId: 5 },
  { name: "Montserrat", globalZoneId: 5 },
  { name: "Argentina", globalZoneId: 5 },
  { name: "Venezuela", globalZoneId: 5 },
  { name: "Uruguay", globalZoneId: 5 },
  { name: "Ecuador", globalZoneId: 5 },
  { name: "Guyana", globalZoneId: 5 },
  { name: "Colombia", globalZoneId: 5 },
  { name: "Surinam", globalZoneId: 5 },
  { name: "Chile", globalZoneId: 5 },
  { name: "Paraguay", globalZoneId: 5 },
  { name: "Falkland Islands (Islas Malvinas)", globalZoneId: 5 },
  { name: "French Guiana", globalZoneId: 5 },
  { name: "Brazil", globalZoneId: 5 },
  { name: "Peru", globalZoneId: 5 },
  { name: "Bolivia", globalZoneId: 5 },
  { name: "Ascension", globalZoneId: 5 },
  { name: "Algeria", globalZoneId: 5 },
  { name: "Angola", globalZoneId: 5 },
  { name: "Uganda", globalZoneId: 5 },
  { name: "Egypt", globalZoneId: 5 },
  { name: "Eswatini", globalZoneId: 5 },
  { name: "Ethiopia", globalZoneId: 5 },
  { name: "Eritrea", globalZoneId: 5 },
  { name: "Ghana", globalZoneId: 5 },
  { name: "Cape Verde", globalZoneId: 5 },
  { name: "Gabon", globalZoneId: 5 },
  { name: "Cameroon", globalZoneId: 5 },
  { name: "Gambia", globalZoneId: 5 },
  { name: "Guinea", globalZoneId: 5 },
  { name: "Guinea-Bissau", globalZoneId: 5 },
  { name: "Kenya", globalZoneId: 5 },
  { name: "Comoros", globalZoneId: 5 },
  { name: "Congo", globalZoneId: 5 },
  { name: "The Democratic Republic of the Congo", globalZoneId: 5 },
  { name: "Sao Tome And Principe", globalZoneId: 5 },
  { name: "Zambia", globalZoneId: 5 },
  { name: "Sierra Leone", globalZoneId: 5 },
  { name: "Djibouti", globalZoneId: 5 },
  { name: "Zimbabwe", globalZoneId: 5 },
  { name: "Sudan", globalZoneId: 5 },
  { name: "Seychelles", globalZoneId: 5 },
  { name: "Equatorial Guinea", globalZoneId: 5 },
  { name: "Senegal", globalZoneId: 5 },
  { name: "St. Helena", globalZoneId: 5 },
  { name: "Cote d'Ivoire", globalZoneId: 5 },
  { name: "Somalia", globalZoneId: 5 },
  { name: "Tanzania", globalZoneId: 5 },
  { name: "Chad", globalZoneId: 5 },
  { name: "Central Africa", globalZoneId: 5 },
  { name: "Tunisia", globalZoneId: 5 },
  { name: "Togo", globalZoneId: 5 },
  { name: "Tristan da Cunha", globalZoneId: 5 },
  { name: "Nigeria", globalZoneId: 5 },
  { name: "Namibia", globalZoneId: 5 },
  { name: "Niger", globalZoneId: 5 },
  { name: "Burkina Faso", globalZoneId: 5 },
  { name: "Burundi", globalZoneId: 5 },
  { name: "Benin", globalZoneId: 5 },
  { name: "Botswana", globalZoneId: 5 },
  { name: "Madagascar", globalZoneId: 5 },
  { name: "Malawi", globalZoneId: 5 },
  { name: "Mali", globalZoneId: 5 },
  { name: "South Africa", globalZoneId: 5 },
  { name: "South Sudan", globalZoneId: 5 },
  { name: "Mauritius", globalZoneId: 5 },
  { name: "Mauritania", globalZoneId: 5 },
  { name: "Mozambique", globalZoneId: 5 },
  { name: "Morocco", globalZoneId: 5 },
  { name: "Libya", globalZoneId: 5 },
  { name: "Liberia", globalZoneId: 5 },
  { name: "Rwanda", globalZoneId: 5 },
  { name: "Lesotho", globalZoneId: 5 },
  { name: "Reunion", globalZoneId: 5 },

  // zona 4
  { name: "United States of America", globalZoneId: 4 },
  { name: "Wake Island", globalZoneId: 4 },
  { name: "Northern Mariana Islands", globalZoneId: 4 },
  { name: "Guam", globalZoneId: 4 },
  { name: "Puerto Rico", globalZoneId: 4 },
  { name: "Virgin Islands", globalZoneId: 4 },
  { name: "American Samoa", globalZoneId: 4 },
  { name: "Midway Islands", globalZoneId: 4 },

  // zona 3
  { name: "Vanuatu", globalZoneId: 3 },
  { name: "Australia", globalZoneId: 3 },
  { name: "Kiribati", globalZoneId: 3 },
  { name: "Cook Islands", globalZoneId: 3 },
  { name: "Samoa", globalZoneId: 3 },
  { name: "Solomon", globalZoneId: 3 },
  { name: "Tuvalu", globalZoneId: 3 },
  { name: "Tonga", globalZoneId: 3 },
  { name: "Nauru", globalZoneId: 3 },
  { name: "New Caledonia", globalZoneId: 3 },
  { name: "New Zealand", globalZoneId: 3 },
  { name: "Papua New Guinea", globalZoneId: 3 },
  { name: "Pitcairn", globalZoneId: 3 },
  { name: "Fiji", globalZoneId: 3 },
  { name: "French Polynesia", globalZoneId: 3 },
  { name: "Other islands of Oceania", globalZoneId: 3 },
  { name: "Canada", globalZoneId: 3 },
  { name: "St. Pierre and Miquelon", globalZoneId: 3 },
  { name: "Mexico", globalZoneId: 3 },
  { name: "United Arab Emirates", globalZoneId: 3 },
  { name: "Yemen", globalZoneId: 3 },
  { name: "Israel", globalZoneId: 3 },
  { name: "Iraq", globalZoneId: 3 },
  { name: "Iran", globalZoneId: 3 },
  { name: "Oman", globalZoneId: 3 },
  { name: "Qatar", globalZoneId: 3 },
  { name: "Kuwait", globalZoneId: 3 },
  { name: "Saudi Arabia", globalZoneId: 3 },
  { name: "Jordan", globalZoneId: 3 },
  { name: "Syria", globalZoneId: 3 },
  { name: "Turkey", globalZoneId: 3 },
  { name: "Bahrain", globalZoneId: 3 },
  { name: "Lebanon", globalZoneId: 3 },
  { name: "Iceland", globalZoneId: 3 },
  { name: "Ireland", globalZoneId: 3 },
  { name: "Azerbaijan", globalZoneId: 3 },
  { name: "Albania", globalZoneId: 3 },
  { name: "Armenia", globalZoneId: 3 },
  { name: "Andorra", globalZoneId: 3 },
  { name: "Italy", globalZoneId: 3 },
  { name: "Vatican City", globalZoneId: 3 },
  { name: "Ukraine", globalZoneId: 3 },
  { name: "Uzbekistan", globalZoneId: 3 },
  { name: "Estonia", globalZoneId: 3 },
  { name: "Austria", globalZoneId: 3 },
  { name: "Netherlands", globalZoneId: 3 },
  { name: "Guernsey", globalZoneId: 3 },
  { name: "Kazakhstan", globalZoneId: 3 },
  { name: "North Macedonia", globalZoneId: 3 },
  { name: "Cyprus", globalZoneId: 3 },
  { name: "Greece", globalZoneId: 3 },
  { name: "Kyrgyz", globalZoneId: 3 },
  { name: "Georgia", globalZoneId: 3 },
  { name: "Croatia", globalZoneId: 3 },
  { name: "Kosovo", globalZoneId: 3 },
  { name: "San Marino", globalZoneId: 3 },
  { name: "Gibraltar", globalZoneId: 3 },
  { name: "Jersey", globalZoneId: 3 },
  { name: "Switzerland", globalZoneId: 3 },
  { name: "Sweden", globalZoneId: 3 },
  { name: "Spain", globalZoneId: 3 },
  { name: "Canary Islands", globalZoneId: 3 },
  { name: "Ladu", globalZoneId: 3 },
  { name: "Ceuta", globalZoneId: 3 },
  { name: "Chafarinas Islands", globalZoneId: 3 },
  { name: "Balearic Islands", globalZoneId: 3 },
  { name: "Melilla", globalZoneId: 3 },
  { name: "Slovakia", globalZoneId: 3 },
  { name: "Slovenia", globalZoneId: 3 },
  { name: "Serbia", globalZoneId: 3 },
  { name: "Tajikistan", globalZoneId: 3 },
  { name: "Czech Republic", globalZoneId: 3 },
  { name: "Denmark", globalZoneId: 3 },
  { name: "Germany", globalZoneId: 3 },
  { name: "Turkmenistan", globalZoneId: 3 },
  { name: "Norway", globalZoneId: 3 },
  { name: "Hungary", globalZoneId: 3 },
  { name: "Finland", globalZoneId: 3 },
  { name: "France", globalZoneId: 3 },
  { name: "Bulgaria", globalZoneId: 3 },
  { name: "Belarus", globalZoneId: 3 },
  { name: "Belgium", globalZoneId: 3 },
  { name: "Poland", globalZoneId: 3 },
  { name: "Bosnia and Herzegovina", globalZoneId: 3 },
  { name: "Northern Islands", globalZoneId: 3 },
  {
    name: "Portugal (including Azores and Madeira Islands ）",
    globalZoneId: 3,
  },
  { name: "Malta", globalZoneId: 3 },
  { name: "Monaco", globalZoneId: 3 },
  { name: "Moldova", globalZoneId: 3 },
  { name: "Montenegro", globalZoneId: 3 },
  { name: "Latvia", globalZoneId: 3 },
  { name: "Lithuania", globalZoneId: 3 },
  { name: "Liechtenstein", globalZoneId: 3 },
  { name: "Romania", globalZoneId: 3 },
  { name: "Luxembourg", globalZoneId: 3 },
  { name: "United Kingdom", globalZoneId: 3 },
  { name: "England", globalZoneId: 3 },
  { name: "Scotland", globalZoneId: 3 },
  { name: "Russian Federation", globalZoneId: 3 },

  // zona 2

  { name: "India", globalZoneId: 2 },
  { name: "Indonesia", globalZoneId: 2 },
  { name: "Cambodia", globalZoneId: 2 },
  { name: "Singapore", globalZoneId: 2 },
  { name: "Sri Lanka", globalZoneId: 2 },
  { name: "Thailand", globalZoneId: 2 },
  { name: "Nepal", globalZoneId: 2 },
  { name: "Pakistan", globalZoneId: 2 },
  { name: "Bangladesh", globalZoneId: 2 },
  { name: "Philippines", globalZoneId: 2 },
  { name: "Bhutan", globalZoneId: 2 },
  { name: "Brunei", globalZoneId: 2 },
  { name: "Vietnam", globalZoneId: 2 },
  { name: "Hong Kong", globalZoneId: 2 },
  { name: "Macao", globalZoneId: 2 },
  { name: "Malaysia", globalZoneId: 2 },
  { name: "Myanmar", globalZoneId: 2 },
  { name: "Maldives", globalZoneId: 2 },
  { name: "Mongolia", globalZoneId: 2 },
  { name: "Laos", globalZoneId: 2 },
  // zona 1
  { name: "Korea", globalZoneId: 1 },
  { name: "Taiwan", globalZoneId: 1 },
  { name: "China", globalZoneId: 1 },
].sort((a, b) => (a.name > b.name ? 1 : -1));
