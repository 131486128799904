import { Box, Button, Typography } from "@mui/material";
import { amber, blue, grey, orange, teal } from "@mui/material/colors";
import { useTranslate } from "../../../../hooks/useTranslate";
import useAuth from "../../../../hooks/context/useAuth";
import { useEffect, useState } from "react";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import { CircularLoading } from "../../Atoms";

interface Props {
  setOrderType(val: OrderType): void;
}

export function SelectOrderType({ setOrderType }: Props) {
  const { strings } = useTranslate();
  const { language, user, userLoadingData } = useAuth();
  const [userCanMakeOrder, setUserCanMakeOrder] = useState(false);

  useEffect(() => {
    setUserCanMakeOrder(user?.user?.canMakeOrders);
  }, [user]);

  return (
    <>
      {userLoadingData ? (
        <CircularLoading text="Loading..." />
      ) : userCanMakeOrder ? (
        <Box
          width={"100%"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={"column"}
          gap={3}
          mb={4}
        >
          <Typography variant="h1">
            {strings.orderModal.titleWichOrder[language]}
          </Typography>
          <Button
            variant="contained"
            onClick={() => setOrderType("orderToBuy")}
            sx={{
              width: 300,
              bgcolor: blue[400],
              "&:hover": { bgcolor: blue[300] },
              fontWeight: 800,
            }}
          >
            {strings.buttons.newService[language]}
          </Button>
          <Button
            fullWidth
            variant="contained"
            onClick={() => setOrderType("askQuestion")}
            sx={{
              width: 300,
              bgcolor: amber[400],
              "&:hover": { bgcolor: amber[200] },
              fontWeight: 800,
            }}
          >
            {strings.buttons.askProduct[language]}
          </Button>
          <Button
            fullWidth
            variant="contained"
            onClick={() => setOrderType("bid")}
            sx={{
              width: 300,
              bgcolor: teal[400],
              "&:hover": { bgcolor: teal[300] },
              fontWeight: 800,
            }}
          >
            {strings.buttons.bid[language]}
          </Button>
          <Button
            fullWidth
            variant="contained"
            onClick={() => setOrderType("auction")}
            sx={{
              width: 300,
              bgcolor: orange[400],
              "&:hover": { bgcolor: orange[300] },
              fontWeight: 800,
            }}
          >
            {strings.buttons.auction[language]}
          </Button>
        </Box>
      ) : (
        <Box
          width={"100%"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={"column"}
          gap={3}
          mb={4}
        >
          <WarningAmberRoundedIcon
            sx={{ fontSize: 40, fill: grey[700], mb: -3 }}
          />
          <Typography
            sx={{ fontSize: 18, p: 3, fontWeight: 500, color: grey[700] }}
          >
            {strings.orderModal.canMakeOrders[language]}
          </Typography>
        </Box>
      )}
    </>
  );
}
