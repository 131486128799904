import { Box, Typography } from "@mui/material";
import useAuth from "../../../hooks/context/useAuth";
import { useTranslate } from "../../../hooks/useTranslate";

import { useBreakpoints } from "../../../hooks/useBreakpoins";
import { colors } from "../../../theme/colors";
import { FadeInContainer } from "../../../components/AtomicDesign/Atoms";
import { blueGrey } from "@mui/material/colors";
import { useEffect, useState } from "react";
import { getDashboard } from "../../../services/getUsersAdmin";
import {
  BoxWithTitle,
  ChartGraphAllProfit,
  ChartGraphByOrderToBuyQTD,
  ChartGraphByProductsProfit,
  ChartGraphByProductsQTD,
  ChartGraphByService,
  ChartGraphByShippmentsProfit,
  ChartGraphShippmentQTD,
  ResumeBox,
} from "../../../components/AtomicDesign/Molecules";

export function AdminDashboard() {
  const { strings } = useTranslate();
  const { language, getUserLocalStorage } = useAuth();
  const { isSmalldesktop } = useBreakpoints();
  const [dashboardInfo, setDashboardInfo] = useState({
    products: 0,
    orders: 0,
    deliveries: 0,
    totalMoneyInSystem: 0,
  });
  const [creditsProfitPerMonth, setCreditsProfitPerMonth] = useState<
    FullProfit[]
  >([]);
  const [creditsProfitServicePerMonth, setCreditsProfitServicePerMonth] =
    useState<ServicesProfit[]>([]);
  const [shippmentsProfit, setShippmentsProfit] = useState<ShippmentProft[]>(
    []
  );
  const [productsProfit, setProductsProfit] = useState<ProductsProfit[]>([]);
  const [orderToBuyQTD, setOrderToBuyQTD] = useState<OrderToBuyQTD[]>([]);
  const [shippmentQTD, setShippmentQTD] = useState<ShippmentQTD[]>([]);
  const [producetsQTD, setProductsQTD] = useState<ProductsQTD[]>([]);

  const getMyDashboardInfo = async () => {
    try {
      const response = await getDashboard(getUserLocalStorage().user?.user?.id);
      setDashboardInfo(response.count);
      setCreditsProfitPerMonth(response.creditsProfitPerMonth);
      setCreditsProfitServicePerMonth(response.creditsProfitServicePerMonth);
      setShippmentsProfit(response.creditsProfitShippmentsPerMonth);
      setProductsProfit(response.creditsProfitProductsService);
      setOrderToBuyQTD(response.totalServices.orderToBuyQTD);
      setShippmentQTD(response.totalServices.deliveriesQTD);
      setProductsQTD(response.totalServices.productsArriveQTD);
    } catch {
      setDashboardInfo({
        deliveries: 0,
        orders: 0,
        products: 0,
        totalMoneyInSystem: 0,
      });
    }
  };

  const resume: any = [
    {
      text: strings.resumeService.product[language],
      icon: "game",
      color: colors.blue[400],
      value: dashboardInfo.products,
    },
    {
      text: strings.resumeService.services[language],
      icon: "service",
      color: colors.yellow[400],
      value: dashboardInfo.orders,
    },
    {
      text: strings.resumeService.shipments[language],
      icon: "shipping",
      color: colors.green[400],
      value: dashboardInfo.deliveries,
    },
    {
      text: strings.resumeService.creditsInSystem[language],
      icon: "credits",
      color: blueGrey[400],
      value: dashboardInfo?.totalMoneyInSystem,
    },
  ];

  useEffect(() => {
    getMyDashboardInfo();
    // eslint-disable-next-line
  }, []);

  return (
    <FadeInContainer>
      <Box
        display={"flex"}
        flexWrap={"wrap"}
        gap={5}
        justifyContent={"center"}
        p={1}
      >
        <ResumeBox data={resume} />

        <Box
          width={"90%"}
          display={"flex"}
          justifyContent={"center"}
          sx={{ background: "#FFF", borderRadius: 2 }}
          p={2}
        >
          <Typography fontSize={30}>Money</Typography>
        </Box>

        <BoxWithTitle
          title={strings.dashboardAdmin.graphs.money[language]}
          width={isSmalldesktop ? "100%" : "45%"}
          padding={"10px 0px"}
        >
          <ChartGraphAllProfit list={creditsProfitPerMonth} />
        </BoxWithTitle>

        <BoxWithTitle
          title={strings.dashboardAdmin.graphs.services[language]}
          width={isSmalldesktop ? "100%" : "45%"}
          padding={"10px 0px"}
        >
          <ChartGraphByService list={creditsProfitServicePerMonth} />
        </BoxWithTitle>

        <BoxWithTitle
          title={strings.dashboardAdmin.graphs.shippments[language]}
          width={isSmalldesktop ? "100%" : "45%"}
          padding={"10px 0px"}
        >
          <ChartGraphByShippmentsProfit list={shippmentsProfit} />
        </BoxWithTitle>

        <BoxWithTitle
          title={strings.dashboardAdmin.graphs.products[language]}
          width={isSmalldesktop ? "100%" : "45%"}
          padding={"10px 0px"}
        >
          <ChartGraphByProductsProfit list={productsProfit} />
        </BoxWithTitle>

        <Box
          width={"90%"}
          display={"flex"}
          justifyContent={"center"}
          sx={{ background: "#FFF", borderRadius: 2 }}
          p={2}
        >
          <Typography fontSize={30}>Total services</Typography>
        </Box>

        <BoxWithTitle
          title={strings.dashboardAdmin.graphs.ordersRequested[language]}
          width={isSmalldesktop ? "100%" : "45%"}
          padding={"10px 0px"}
        >
          <ChartGraphByOrderToBuyQTD list={orderToBuyQTD} />
        </BoxWithTitle>

        <BoxWithTitle
          title={strings.dashboardAdmin.graphs.shippmentsRequested[language]}
          width={isSmalldesktop ? "100%" : "45%"}
          padding={"10px 0px"}
        >
          <ChartGraphShippmentQTD list={shippmentQTD} />
        </BoxWithTitle>

        <BoxWithTitle
          title={strings.dashboardAdmin.graphs.productsArrive[language]}
          width={isSmalldesktop ? "100%" : "45%"}
          padding={"10px 0px"}
        >
          <ChartGraphByProductsQTD list={producetsQTD} />
        </BoxWithTitle>
      </Box>
    </FadeInContainer>
  );
}
