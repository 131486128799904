import { api } from "./api";

export const calcTaxesService = async (
  useMycredits: boolean,
  discount: number,
  userID: number,
  price: number,
  paymentChoice: string,
  itemsQuantity: number,
  orderType: OrderType,
  orderID?: number
) => {
  const response2 = await api.post("/calc-taxes", {
    price,
    paymentChoice,
    userID,
    discount,
    useMycredits,
    itemsQuantity,
    orderID,
    orderType,
  });
  return response2.data;
};

export const createOrderToBuy = async (data: ICreateOrderToBuy) => {
  const response2 = await api.post("/create-order-to-buy", { data });
  return response2.data;
};

export const getOrdersToBuy = async (
  userID: number,
  filter: {
    key: string;
    searchTerm: string;
    itensPerPage: number;
    offset: number;
  }
) => {
  const response2 = await api.get("/find-orders-to-buy", {
    params: {
      userID: userID,
      searchKey: filter.key,
      searchTerm: filter.searchTerm,
      itensPerPage: filter.itensPerPage,
      offset: filter.offset,
    },
  });
  return response2.data;
};

export const getOneOrdersToBuy = async (orderID: number) => {
  const response2 = await api.get("/find-oneorder-to-buy", {
    params: { orderID },
  });
  return response2.data;
};

// admin

export const getOrdersAdminToBuy = async (
  userid: number,
  filter: {
    key: string;
    searchTerm: string;
    itensPerPage: number;
    offset: number;
  }
) => {
  const response2 = await api.get("/find-orders-to-buy", {
    params: {
      userID: userid,
      searchKey: filter.key,
      searchTerm: filter.searchTerm,
      itensPerPage: filter.itensPerPage,
      offset: filter.offset,
    },
  });
  return response2.data;
};

export const getOrdersAdminAuctionsToBuy = async (
  userid: number,
  filter: {
    key: string;
    searchTerm: string;
    itensPerPage: number;
    offset: number;
  }
) => {
  const response2 = await api.get("/find-orders-auction-to-buy", {
    params: {
      userID: userid,
      searchKey: filter.key,
      searchTerm: filter.searchTerm,
      itensPerPage: filter.itensPerPage,
      offset: filter.offset,
    },
  });
  return response2.data;
};

export const updateOrderAdmin = async (data: any) => {
  const response2 = await api.post("/update-order-to-buy-admin", { data });
  return response2.data;
};

export const chargeByStripeOrderToBuy = async (data: ChargeCardOrderToBuy) => {
  const response2 = await api.post("/charge-ordertobuy", {
    orderToBuyID: data.itemID,
    totalValue: data.totalValue,
  });
  return response2.data;
};

export const verifyOrderToBuyPayment = async (
  chargeID: string,
  orderID: number
) => {
  const response2 = await api.post("/confirm-payment-ordertobuy", {
    sessionid: chargeID,
    orderToBuyID: orderID,
  });
  return response2.data;
};
