import {
  CardActions,
  CardContent,
  Card,
  Box,
  Typography,
  Button as MaterialButton,
} from "@mui/material";
import {
  DataText,
  Button,
  NoData,
  CircularLoading,
  CardImages,
  CustomToolTip,
  MailNotification,
  WarningMessageYellow,
} from "../../Atoms";
import { useBreakpoints } from "../../../../hooks/useBreakpoins";
import { UseDateTime } from "../../../../hooks/useDateTime";
import { useCommonData } from "../../../../hooks/useCommonData";
import { amber, blue, green, red } from "@mui/material/colors";
import { CustomModal } from "../../Organisms";
import { useState } from "react";
import useAuth from "../../../../hooks/context/useAuth";
import { useJPYmoney } from "../../../../hooks/useJPYmoney";
import { useTranslate } from "../../../../hooks/useTranslate";
import { differenceInDays } from "date-fns";

interface Props {
  data: Products[];
  onClickCredit(val: Products): void;
  isLoading?: boolean;
  productSolicitation?(
    data: any,
    solicitationType: {
      text: "removePackage" | "takeNewPhotos" | "";
      price: number;
    }
  ): void;
}

interface ProductSelected {
  productID: number;
  suiteId: number;
  product: Products;
}

export function ProductsCards({
  data,
  onClickCredit,
  isLoading,
  productSolicitation,
}: Props) {
  const { strings } = useTranslate();
  const { labelStatus, adminOrder, shipCards } = useCommonData();
  const { JPYmoney } = useJPYmoney();
  const { formatedDate } = UseDateTime();
  const { isSmalldesktop, isMobile, mediumDesktop, semiSmalldesktop } =
    useBreakpoints();
  const [productID, setProductID] = useState<ProductSelected>({
    productID: 0,
    suiteId: 0,
    product: {} as Products,
  });
  const [openModal, setOpenModal] = useState(false);
  const [selectSolicitationType, setSelectSolicitationType] = useState({
    text: "" as "removePackage" | "takeNewPhotos" | "",
    price: 0,
  });
  const { user, siteConfig, language } = useAuth();

  const titleWidth = 100;

  const breakpoints = () => {
    if (isMobile) return "100%";
    if (isSmalldesktop) return "48.5%";
    if (semiSmalldesktop) return "32%";
    if (mediumDesktop) return "23.9%";
    return "19%";
  };

  const hasNewMessage = (product: Products) => {
    const messages = product?.WarningMessage;
    if (!messages) return false;
    if (messages?.length === 0) return false;
    if (!messages[0]?.read && user?.user?.id !== messages[0]?.usersId) {
      if (user?.user?.role === messages[0]?.type) {
        return false;
      }
      return true;
    }
    return false;
  };

  const removePKText = (item: Products) => {
    if (item?.adminRemovePackage) {
      return {
        text: strings.productsPage.packageRemoved[language],
        color: green[400],
      };
    }
    if (item?.removePackage) {
      return {
        text: strings.productsPage.packageRemove[language],
        color: amber[400],
      };
    }
    return {
      text: strings.productsPage.packageNoRemove[language],
      color: "transparent",
    };
  };

  const takePhotosText = (item: Products) => {
    if (item?.adminTakeNewPhotos) {
      return {
        text: strings.productsPage.photosDone[language],
        color: green[400],
        badgeAlign: "center",
      };
    }
    if (item?.takeNewPhotos) {
      return {
        text: strings.productsPage.tankeNewPhotos[language],
        color: amber[400],
        badgeAlign: "center",
      };
    }
    return { text: "OK", color: "transparent", badgeAlign: "left" };
  };

  return (
    <Box
      width={"auto"}
      display="flex"
      flexWrap={"wrap"}
      rowGap={1.5}
      columnGap={1.5}
      justifyContent={"flex-start"}
    >
      {isLoading ? (
        <CircularLoading />
      ) : data && data?.length > 0 ? (
        data.map((item, index) => (
          <Card
            sx={{
              width: breakpoints(),
              minWidth: "245px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              border: `2px solid ${blue[50]}`,
            }}
            key={index}
          >
            <Box sx={{ bgcolor: blue[50] }}>
              <CustomToolTip text={item?.name}>
                <Typography
                  p={1}
                  variant="h2"
                  fontWeight={500}
                  sx={{
                    maxHeight: 14,
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  {item?.name}
                </Typography>
              </CustomToolTip>
            </Box>
            <CardImages images={item?.ProductImages} />
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  justifyContent: "center",
                  rowGap: 0.5,
                }}
              >
                <DataText
                  title={shipCards.arrivedAt}
                  text={formatedDate(item?.arrivedAt)}
                  titleProps={{ width: titleWidth }}
                />

                <DataText
                  title={shipCards.daysInStock}
                  text={
                    differenceInDays(new Date(), new Date(item?.arrivedAt)) +
                    " " +
                    shipCards.days +
                    "(s)"
                  }
                  titleProps={{ width: titleWidth }}
                />

                <DataText
                  title={item?.kg > 999 ? shipCards.kg : shipCards.g}
                  text={`${item?.kg.toLocaleString()}`}
                  titleProps={{ width: titleWidth }}
                />
                <DataText
                  title={"ID:"}
                  text={item.id}
                  titleProps={{ width: titleWidth }}
                />

                <DataText
                  title={"Suite:"}
                  text={`${item?.Suite?.id}`}
                  titleProps={{ width: titleWidth }}
                />

                <DataText
                  title={strings.productsPage.cards.bought[language]}
                  text={`${item?.itemsQuantity}`}
                  titleProps={{ width: titleWidth }}
                />

                <DataText
                  title={"Stock:"}
                  text={`${item?.leftInStock}`}
                  titleProps={{ width: titleWidth }}
                />

                <DataText
                  title={shipCards.shipped}
                  text={item?.alreadyShipped}
                  titleProps={{ width: titleWidth }}
                />
                <DataText
                  title={strings.productsPage.box[language]}
                  text={removePKText(item).text}
                  textProps={{
                    bgcolor: removePKText(item).color,
                    borderRadius: 10,
                    width: 100,
                    textAlign: item?.removePackage ? "center" : "left",
                  }}
                  titleProps={{ width: titleWidth }}
                />
                <DataText
                  title={"Photos:"}
                  text={takePhotosText(item).text}
                  textProps={{
                    bgcolor: takePhotosText(item).color,
                    borderRadius: 10,
                    width: 100,
                    textAlign: takePhotosText(item).badgeAlign as any,
                  }}
                  titleProps={{ width: titleWidth }}
                />
              </Box>
            </CardContent>
            <CardActions
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Button
                config={{
                  size: "small",
                  fullWidth: false,
                  onClick: () => onClickCredit(item),
                }}
                style={{ color: "white" }}
              >
                {labelStatus.button.label}
              </Button>
              <MailNotification hasNewMessage={hasNewMessage(item)} disabled />
              {user?.user?.role === "client" && item.leftInStock > 0 && (
                <MaterialButton
                  size="small"
                  variant="contained"
                  onClick={() => {
                    setProductID({
                      productID: item?.id,
                      product: item,
                      suiteId: item?.suiteId,
                    });
                    setOpenModal(true);
                  }}
                >
                  {strings.buttons.newSolicitation[language]}
                </MaterialButton>
              )}

              {/* {user?.user?.role === "admin" && item.removePackage && (
                <Chip
                  label={"Remover embalagem"}
                  style={{
                    background: amber[500],
                    color: "white",
                    fontWeight: 500,
                  }}
                />
              )} */}
            </CardActions>
          </Card>
        ))
      ) : (
        <NoData text="No data" />
      )}
      <CustomModal
        openModal={openModal}
        setOpenModal={() => {
          setOpenModal(false);
          setSelectSolicitationType({ text: "", price: 0 });
        }}
        width={isSmalldesktop ? "90%" : "30%"}
        title={
          selectSolicitationType.text === ""
            ? strings.productsPage.solicitationModal.title[language]
            : ""
        }
      >
        <Box
          display="flex"
          flexDirection={"column"}
          alignItems={"center"}
          gap={1}
          mb={2}
        >
          {selectSolicitationType.text === "" && (
            <Box p={3} display={"flex"} flexDirection={"column"} gap={3}>
              <MaterialButton
                variant="contained"
                onClick={() =>
                  setSelectSolicitationType({
                    text: "removePackage",
                    price:
                      siteConfig?.removePackedPrice *
                      productID?.product?.leftInStock,
                  })
                }
                sx={{
                  width: 300,
                  bgcolor: blue[400],
                  "&:hover": { bgcolor: blue[300] },
                  fontWeight: 800,
                }}
              >
                {strings.buttons.removeFromPackage[language]}
              </MaterialButton>
              <MaterialButton
                fullWidth
                variant="contained"
                onClick={() =>
                  setSelectSolicitationType({
                    text: "takeNewPhotos",
                    price:
                      siteConfig?.takeNewPhotosPrice *
                      productID?.product?.leftInStock,
                  })
                }
                sx={{
                  width: 300,
                  bgcolor: blue[400],
                  "&:hover": { bgcolor: blue[400] },
                  fontWeight: 800,
                }}
              >
                {strings.buttons.takeNewPhotos[language]}
              </MaterialButton>
            </Box>
          )}

          {selectSolicitationType.text !== "" && (
            <MaterialButton
              variant="contained"
              onClick={() => setSelectSolicitationType({ text: "", price: 0 })}
              sx={{
                width: 300,
                bgcolor: blue[400],
                "&:hover": { bgcolor: blue[300] },
                fontWeight: 800,
              }}
            >
              {strings.buttons.turnBack[language]}
            </MaterialButton>
          )}

          {selectSolicitationType.text === "removePackage" && (
            <Box p={2}>
              <Typography mt={-5}>
                <WarningMessageYellow
                  message={
                    strings.productsPage.solicitationModal.removePackageText[
                      language
                    ]
                  }
                />
              </Typography>
              <Typography>
                {strings.productsPage.solicitationModal.creditWarning[language]}
                {JPYmoney(
                  siteConfig?.removePackedPrice *
                    productID?.product?.leftInStock
                )}
              </Typography>
              <Typography mt={2} fontSize={14} textAlign={"center"}>
                {
                  strings.productsPage.solicitationModal.explanationRemovePrice[
                    language
                  ]
                }
              </Typography>
            </Box>
          )}
          {selectSolicitationType.text === "takeNewPhotos" && (
            <Box p={2}>
              <Typography>
                {strings.productsPage.solicitationModal.newPhotos[language]}
              </Typography>
              <Typography>
                {strings.productsPage.solicitationModal.creditWarning[language]}
                {JPYmoney(siteConfig?.takeNewPhotosPrice)}
              </Typography>
            </Box>
          )}
        </Box>

        {selectSolicitationType.text !== "" && (
          <Box display={"flex"} justifyContent={"center"} gap={5} mb={2}>
            <MaterialButton
              sx={{ bgcolor: green[500], color: "white" }}
              style={{ width: 100 }}
              onClick={() => {
                productSolicitation &&
                  productSolicitation(
                    {
                      data: {
                        productID: productID?.productID,
                        suiteId: productID?.suiteId,
                      },
                    },
                    selectSolicitationType
                  );
                setOpenModal(false);
                setSelectSolicitationType({ text: "", price: 0 });
              }}
            >
              {adminOrder.confirmBtn}
            </MaterialButton>
            <MaterialButton
              sx={{ bgcolor: red[500], color: "white" }}
              style={{ width: 100 }}
              onClick={() => {
                setOpenModal(false);
                setSelectSolicitationType({ text: "", price: 0 });
              }}
            >
              {adminOrder.cancelBtn}
            </MaterialButton>
          </Box>
        )}
      </CustomModal>
    </Box>
  );
}
