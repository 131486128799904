import {
  Box,
  Button,
  Divider,
  FormHelperText,
  Typography,
} from "@mui/material";
import { BoxContent, CircularLoading } from "../../Atoms";
import {
  DropFileInput,
  GenericInput,
  GenericSelect,
  MessageBox,
  MessageIcon,
} from "../../Molecules";
import { useBreakpoints } from "../../../../hooks/useBreakpoins";
import treasureBoxLogo from "../../../../assets/images/NEWTREASUREBOXLOGO2.png";
import useAuth from "../../../../hooks/context/useAuth";
import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { CustomModal } from "../Modal";
import { Validation } from "./validationSchema";
import { useCommonData } from "../../../../hooks/useCommonData";
import useCreditsAdmin from "../../../../hooks/context/useCreditsAdmin";
import { UpdateCreditInstance } from "../../../../types/credits";
import { amber, green, red } from "@mui/material/colors";
import { useJPYmoney } from "../../../../hooks/useJPYmoney";
import useToast from "../../../../hooks/context/useToast";
import axios from "axios";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";

interface Props {
  creditID?: number | null | undefined;
  handleSubmit(val: any): void;
  isLoading: boolean;
}

export function CreditBoxAdmin({ creditID, handleSubmit, isLoading }: Props) {
  const { isSmalldesktop } = useBreakpoints();
  const { setToastData, setOpen } = useToast();
  const { JPYmoney } = useJPYmoney();
  const { orderModal, adminButtons, paymentChoices } = useCommonData();
  const { getUserLocalStorage, language } = useAuth();
  const { validationSchema } = Validation(language);
  const { user } = getUserLocalStorage();
  const {
    credit,
    sendNewMessage,
    readAllMessages,
    setKeepAttMessages,
    fileRemoveAdmin,
    verifyPayment,
  } = useCreditsAdmin();
  const [messageModal, setMessageModal] = useState(false);
  const [messageTooltip, setMessageTooltip] = useState(true);
  const [currencyLoading, setCurrencyLoading] = useState(false);
  const [currencyBRL, setCurrencyBRL] = useState("0");

  const formik = useFormik<UpdateCreditInstance>({
    initialValues: {
      creditValue: "",
      paymentChoice: "",
      userID: credit?.usersId,
      creditID: credit?.id,
      status: "underAnalysis",
    },
    validationSchema,
    async onSubmit(values) {
      handleSubmit({
        status: values.status,
        creditValue: values.creditValue,
        userID: values.userID,
        creditID: values.creditID,
      });
      formik.resetForm();
    },
  });

  const messages = useMemo(
    () =>
      credit?.WarningMessage?.sort((a: IMessage, b: IMessage) =>
        a?.created < b?.created ? 1 : -1
      ),
    [credit?.WarningMessage]
  );

  useEffect(() => {
    // controla se o modal vai ser de cadastro ou edição
    if (credit?.id) {
      formik.setFieldValue("userID", credit?.usersId ?? 0);
      formik.setFieldValue("creditValue", credit?.creditValue ?? "");
      formik.setFieldValue("creditID", credit?.id ?? "");
      formik.setFieldValue("paymentChoice", credit?.paymentChoice ?? "");
    }

    setTimeout(() => {
      setMessageTooltip(false);
    }, 5000);
    // eslint-disable-next-line
  }, [credit, creditID]);

  useEffect(() => {
    if (creditID) {
      setKeepAttMessages(true);
    }

    return () => setKeepAttMessages(false);
    // eslint-disable-next-line
  }, []);

  const hasNewMessage = () => {
    if (!messages) return false;
    if (messages?.length === 0) return false;
    if (!messages[0]?.read && user?.user?.id !== messages[0]?.usersId) {
      if (user?.user?.role === messages[0]?.type) {
        return false;
      }
      return true;
    }
    return false;
  };

  const showBtns = () => {
    if (credit?.status === "credited") return false;
    if (credit?.status === "canceled") return false;
    if (credit?.status === "notPaid") return false;
    if (creditID) return true;
    return true;
  };

  const calcPaymentTax = (credits: number) => {
    if (formik.values.paymentChoice === "pix") {
      return {
        tax: JPYmoney(credits * 0.06),
        total: JPYmoney(credits * 0.06 + Number(formik.values.creditValue)),
        onlyNumber: credits * 0.06 + Number(formik.values.creditValue),
      };
    }
    if (formik.values.paymentChoice === "paypal") {
      return {
        tax: JPYmoney(credits * 0.07),
        total: JPYmoney(credits * 0.07 + Number(formik.values.creditValue)),
        onlyNumber: credits * 0.07 + Number(formik.values.creditValue),
      };
    }
    if (formik.values.paymentChoice === "card") {
      return {
        tax: JPYmoney(Math.ceil(credits / 0.959 - credits)),
        total: JPYmoney(
          credits / 0.959 - credits + Number(formik.values.creditValue)
        ),
        onlyNumber:
          credits / 0.959 - credits + Number(formik.values.creditValue),
      };
    }
    return { tax: 0, total: 0, onlyNumber: 0 };
  };

  const getCurrency = async () => {
    setCurrencyLoading(true);
    try {
      const calcTotal = calcPaymentTax(
        Number(formik.values.creditValue)
      ).onlyNumber;
      if (calcTotal > 0) {
        const jpyXBRL = await axios.get(
          "https://economia.awesomeapi.com.br/last/JPY-BRL"
        );
        setCurrencyBRL((calcTotal * jpyXBRL?.data?.JPYBRL?.high).toFixed(2));
      }
    } catch {
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "error",
        message:
          "Ocorreu um erro ao calcular o valor em Reais, tente novamente.",
      });
      setOpen(true);
      setCurrencyBRL("0");
    } finally {
      setCurrencyLoading(false);
    }
  };

  useEffect(() => {
    if (formik.values.paymentChoice === "pix") {
      getCurrency();
    }
    // eslint-disable-next-line
  }, [formik.values.paymentChoice, formik.values.creditValue]);

  const isAdmin = user?.user?.role === "admin";
  return (
    <BoxContent config={{ minWidth: "180px", p: 3 }}>
      {isLoading ? (
        <CircularLoading />
      ) : (
        <>
          <Box width={"100%"} display={"flex"} justifyContent={"space-between"}>
            {creditID && (
              <MessageIcon
                hasNewMessage={hasNewMessage()}
                openModal={() => setMessageModal(true)}
                tooltipOpen={messageTooltip}
                text={orderModal.tooltip}
                chatButton
              />
            )}

            <img src={treasureBoxLogo} width={150} alt="treasurebox logo" />
          </Box>
          <GenericInput
            hasIcon={false}
            onChange={(value, name) =>
              formik.setFieldValue(name, Number(value))
            }
            error={
              formik?.touched?.creditValue && formik?.errors?.creditValue
                ? true
                : false
            }
            errorMessage={
              formik?.touched?.creditValue ? formik?.errors?.creditValue : ""
            }
            value={formik?.values?.creditValue}
            placeholder={orderModal.creditValue}
            inputName="creditValue"
            inputType="number"
            disabled={!showBtns()}
          />

          <GenericSelect
            formWidth="98%"
            onChange={(value, name) => formik.setFieldValue(name, value)}
            error={
              formik?.touched?.paymentChoice && formik?.errors?.paymentChoice
                ? true
                : false
            }
            errorMessage={
              formik?.touched?.paymentChoice
                ? formik?.errors?.paymentChoice
                : ""
            }
            value={formik?.values?.paymentChoice}
            placeholder={orderModal.payment}
            name="paymentChoice"
            options={paymentChoices}
            disabled={creditID ? true : false}
          />

          <Box
            display={"flex"}
            justifyContent={"space-between"}
            width={"98%"}
            mb={1}
            mt={1}
          >
            <Typography>Tax</Typography>
            <Typography>
              {calcPaymentTax(Number(formik.values.creditValue)).tax}
            </Typography>
          </Box>
          <Divider sx={{ width: "98%" }} />
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            width={"98%"}
            mb={1}
            mt={1}
          >
            <Typography>Total</Typography>
            <Typography>
              {calcPaymentTax(Number(formik.values.creditValue)).total}
            </Typography>
          </Box>
          <Divider sx={{ width: "98%" }} />
          {formik.values.paymentChoice === "pix" && (
            <>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                width={"98%"}
                mb={1}
                mt={1}
              >
                <Typography>BRL (reais)</Typography>
                {currencyLoading ? (
                  <CircularLoading />
                ) : (
                  <Typography>{currencyBRL}</Typography>
                )}
              </Box>
            </>
          )}
          {formik.values.paymentChoice !== "card" && (
            <>
              <Typography variant="h1" mb={3} mt={3}>
                {orderModal.proofPayment}
              </Typography>

              <DropFileInput
                onFileChange={(files) => formik.setFieldValue("files", files)}
                oldFiles={credit?.code ? [{ code: credit?.code }] : []}
                filesQtd={1}
                fileRemoveAdmin={() =>
                  fileRemoveAdmin && fileRemoveAdmin(credit?.id)
                }
                disabledDeleteBTN={!showBtns()}
              />
            </>
          )}
          {isAdmin && credit?.paymentIntent !== "" && (
            <Box
              sx={{
                border: "1px solid grey",
                borderRadius: 2,
                mb: 2,
                mt: 2,
                p: 1,
              }}
            >
              <Typography textAlign={"center"}>Pay by credit card</Typography>
              <Typography>Payment intent: {credit?.paymentIntent} </Typography>
              <Typography sx={{ wordBreak: "break-all" }}>
                Charge: {credit?.chargeID}
              </Typography>
            </Box>
          )}
          {isAdmin && credit?.status === "notPaid" && (
            <>
              <FormHelperText
                sx={{ bgcolor: amber[100], borderRadius: 2, p: 1 }}
              >
                <WarningAmberRoundedIcon sx={{ marginBottom: -0.8 }} />
                The customer will need to make the payment again, this credit
                order was not paid
              </FormHelperText>
            </>
          )}

          {credit?.status === "processingPayment" && (
            <>
              <FormHelperText
                sx={{ bgcolor: amber[100], borderRadius: 2, p: 1 }}
              >
                <WarningAmberRoundedIcon sx={{ marginBottom: -0.8 }} />
                Payment has not been fully processed, please click below to
                verify payment
              </FormHelperText>
              <Button
                sx={{ mt: 2 }}
                variant="contained"
                fullWidth
                onClick={() => verifyPayment(credit?.chargeID, credit?.id)}
              >
                Verify payment
              </Button>
            </>
          )}
          {showBtns() && (
            <>
              <Button
                sx={{
                  mt: 3,
                  bgcolor: amber[900],
                  "&:hover": { bgcolor: amber[200] },
                }}
                fullWidth
                variant="contained"
                onClick={() => {
                  formik.setFieldValue("status", "waitingPayment");
                  formik.handleSubmit();
                }}
              >
                {adminButtons.waitingPayment}
              </Button>
              <Button
                sx={{
                  mt: 3,
                  bgcolor: green[700],
                  "&:hover": { bgcolor: green[200] },
                }}
                fullWidth
                variant="contained"
                onClick={() => {
                  formik.setFieldValue("status", "credited");
                  formik.handleSubmit();
                }}
              >
                {adminButtons.credited}
              </Button>
              <Button
                sx={{
                  mt: 3,
                  bgcolor: red[500],
                  "&:hover": { bgcolor: red[200] },
                }}
                fullWidth
                variant="contained"
                onClick={() => {
                  formik.setFieldValue("status", "canceled");
                  formik.handleSubmit();
                }}
              >
                {adminButtons.cancel}
              </Button>
            </>
          )}

          <CustomModal
            openModal={messageModal}
            setOpenModal={setMessageModal}
            width={isSmalldesktop ? "90%" : "30%"}
          >
            <MessageBox
              data={messages}
              sendMessage={(newMessage) =>
                sendNewMessage({
                  description: newMessage,
                  orderID: credit?.id,
                  type: user.user.role,
                  userID: user.user.id,
                  messageType: "credits",
                })
              }
              readMessages={() => readAllMessages(credit?.id)}
            />
          </CustomModal>
        </>
      )}
    </BoxContent>
  );
}
