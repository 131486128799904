import useAuth from "./context/useAuth";
import { useTranslate } from "./useTranslate";
import { green, amber, red, blue, teal, grey } from "@mui/material/colors";

export function useCommonData() {
  const { strings } = useTranslate();
  const { language } = useAuth();
  const paymentChoices = [
    { label: "Transferwise", value: "wise" },
    { label: "Pix (Brasil)", value: "pix" },
    { label: "PayPal", value: "paypal" },
    { label: "Credit Card", value: "card" },
  ];

  const pixDisabledMessage = strings.orderCards.pixDisabled[language];
  const auctionSteps = {
    0: strings.newOrderPage.steps.ordered[language],
    1: strings.newOrderPage.steps.auctionInProgress[language],
    2: strings.newOrderPage.steps.auctionLost[language],
    3: strings.newOrderPage.steps.auctionLost[language],
    4: strings.newOrderPage.steps.auctionWin[language],
  };

  const labelStatus = {
    processingPayment: {
      label: strings.orderCards.labels.processingPayment[language],
      color: amber[800],
    },
    notPaid: {
      label: strings.orderCards.labels.notPaid[language],
      color: red[300],
    },
    waitingPayment: {
      label: strings.orderCards.labels.waitingPayment[language],
      color: amber[500],
    },
    orderDone: {
      label: strings.orderCards.labels.orderDone[language],
      color: green[700],
    },
    shipped: {
      label: strings.orderCards.labels.shipped[language],
      color: green[700],
    },
    waitingCustomer: {
      label: strings.orderCards.labels.waitingCustomer[language],
      color: amber[900],
    },
    waitingStore: {
      label: strings.orderCards.labels.waitingStore[language],
      color: amber[900],
    },
    cancelOrder: {
      label: strings.orderCards.labels.cancelOrder[language],
      color: red[500],
    },
    orderType: {
      label: strings.orderCards.labels.orderType[language],
      color: "",
    },
    onAnalysis: {
      label: strings.orderCards.labels.onAnalysis[language],
      color: amber[900],
    },
    editDelivery: {
      label: strings.orderCards.labels.editDelivery[language],
      color: grey[900],
    },
    cancelOrderWithRefund: {
      label: strings.orderCards.labels.cancelOrderWithRefund[language],
      color: red[900],
    },
    ordered: {
      label: strings.orderCards.labels.ordered[language],
      color: blue[200],
    },
    deliveryRequested: {
      label: strings.orderCards.labels.ordered[language],
      color: blue[200],
    },
    paymentSent: {
      label: strings.orderCards.labels.paymentSent[language],
      color: teal[300],
    },
    button: {
      label: strings.buttons.open[language],
      color: "",
    },
    canceled: {
      label: strings.creditCards.canceled[language],
      color: red[500],
    },
    credited: {
      label: strings.creditCards.credited[language],
      color: green[500],
    },
    underAnalysis: {
      label: strings.creditCards.underAnalysis[language],
      color: amber[300],
    },
  };

  const shippmentLabelStatus = {
    processingPayment: {
      label: strings.orderCards.labels.processingPayment[language],
      color: amber[800],
    },
    notPaid: {
      label: strings.orderCards.labels.notPaid[language],
      color: red[300],
    },
    waitingPayment: {
      label: strings.orderCards.labels.waitingPayment[language],
      color: amber[500],
    },
    shipped: {
      label: strings.orderCards.labels.shipped[language],
      color: green[700],
    },
    waitingCustomer: {
      label: strings.orderCards.labels.waitingCustomer[language],
      color: amber[900],
    },
    waitingStore: {
      label: strings.orderCards.labels.waitingStore[language],
      color: amber[900],
    },
    cancelOrder: {
      label: strings.orderCards.labels.cancelOrder[language],
      color: red[500],
    },
    deliverieRequested: {
      label: strings.orderCards.labels.deliverieRequested[language],
      color: blue[200],
    },
    paymentSent: {
      label: strings.orderCards.labels.paymentSent[language],
      color: teal[300],
    },
    canceled: {
      label: strings.creditCards.canceled[language],
      color: red[500],
    },
    onAnalysis: {
      label: strings.creditCards.onAnalysis[language],
      color: amber[300],
    },
    editDelivery: {
      label: strings.creditCards.editDelivery[language],
      color: grey[300],
    },
    cancelWithRefund: {
      label: strings.creditCards.cancelWithRefund[language],
      color: red[500],
    },
  };

  const userStatus = {
    permitted: {
      label: strings.userStatus.permitted[language],
      color: green[500],
    },
    blocked: {
      label: strings.userStatus.blocked[language],
      color: red[900],
    },
  };

  const orderTypeStrings = {
    orderToBuy: strings.orderCards.orderType.orderToBuy[language],
    askQuestion: strings.orderCards.orderType.askQuestion[language],
    bid: strings.orderCards.orderType.bid[language],
    auction: strings.orderCards.orderType.auction[language],
  };

  const filter = {
    productName: strings.filter.productName[language],
    userName: strings.filter.userName[language],
    payMethod: strings.filter.payMethod[language],
    title: strings.filter.title[language],
    orderType: strings.filter.orderType[language],
  };

  const orderFilterOptions = {
    orderToBuy: strings.orderCards.orderType.orderToBuy[language],
    askQuestion: strings.orderCards.orderType.askQuestion[language],
    bid: strings.orderCards.orderType.bid[language],
    auction: strings.orderCards.orderType.auction[language],
  };

  const adminButtons = {
    waitingPayment: strings.buttons.waitingPayment[language],
    orderDone: strings.buttons.orderDone[language],
    cancelOrder: strings.buttons.cancelOrder[language],
    cancelOrderWithRefund: strings.buttons.cancelOrderWithRefund[language],
    credited: strings.buttons.credited[language],
    cancel: strings.buttons.cancelBtn[language],
  };

  const orderModal = {
    pixPayment: strings.orderModal.pixPayment[language],
    cardPayment: strings.orderModal.cardPayment[language],
    paypalPayment: strings.orderModal.paypalPayment[language],
    wisePayment: strings.orderModal.wisePayment[language],

    price: strings.orderModal.price[language],
    serviceTax: strings.orderModal.serviceTax[language],
    subTotal: strings.orderModal.subTotal[language],
    discount: strings.orderModal.discount[language],
    useCredit: strings.orderModal.useCredit[language],
    paymentTax: strings.orderModal.paymentTax[language],
    total: strings.orderModal.total[language],
    title: strings.orderModal.title[language],
    quantity: strings.orderModal.qtd[language],
    payment: strings.orderModal.payment[language],
    proofPayment: strings.orderModal.proofPayment[language],
    tooltip: strings.orderModal.tooltip[language],
    creditValue: strings.creditCards.pricePlaceHolder[language],
    warningCredits: strings.creditCards.warning[language],
    errorFile: strings.orderModal.errorFile[language],
    name: strings.orderModal.productName[language],
    trackingCode: strings.orderModal.trackingCode[language],
    boxWeight: strings.orderModal.boxWeight[language],
    kg: strings.orderModal.kg[language],
    arrivedAt: strings.orderModal.arrivedAt[language],
    shippedAt: strings.orderModal.shippedAt[language],
    choiceRefund: strings.orderModal.choiceRefund[language],
  };

  const adminOrder = {
    changeStatus: strings.orderModal.changeStatus[language],
    canceledError: strings.orderModal.errorCanceled[language],
    canceledErrorFullValue: strings.orderModal.errorCanceledFull[language],
    confirmBtn: strings.buttons.confirm[language],
    cancelBtn: strings.buttons.cancel[language],
    limitWeight: strings.orderModal.limitWeight[language],
    limitWeightFirst: strings.orderModal.limitWeightFirst[language],
    exceedMaximalWeight: strings.orderModal.exceedMaximalWeight[language],
  };

  const termsAndconditions = {
    title: strings.termsConditions.title[language],
  };

  const addressStrings = {
    address: strings.myAddress.labels.address[language],
    state: strings.myAddress.labels.state[language],
    number: strings.myAddress.labels.number[language],
    cityCountry: strings.myAddress.labels.city[language],
    complement: strings.myAddress.labels.Neighborhood[language],
    newComplement: strings.myAddress.labels.complementInCard[language],
    postalCode: strings.myAddress.labels.zipCode[language],
    receiver: strings.myAddress.labels.receiver[language],
    ssn: strings.myAddress.labels.ssn[language],
  };

  const shipCards = {
    days: strings.shippmentPage.cards.days[language],
    arrivedAt: strings.shippmentPage.cards.arrivedAt[language],
    daysInStock: strings.shippmentPage.cards.daysInStock[language],
    kg: strings.shippmentPage.cards.kg[language],
    g: strings.shippmentPage.cards.g[language],
    shipped: strings.shippmentPage.cards.shipped[language],
    type: strings.shippmentPage.cards.type[language],
    shippment: strings.shippmentPage.modal.shippment[language],
  };

  const shippmentModal = {
    popover: strings.shippmentPage.modal.popover[language],
    selectedItems: strings.shippmentPage.modal.selectedItems[language],
    quantity: strings.shippmentPage.modal.quantity[language],
    plasticB: strings.shippmentPage.modal.plasticB[language],
    dhlChoice: strings.shippmentPage.modal.dhlChoice[language],
    reinforcedBox: strings.shippmentPage.modal.reinforcedBoxPrice[language],
    defaultBoxWeight: strings.shippmentPage.modal.defaultBoxWeight[language],
    itemsWeight: strings.shippmentPage.modal.itemsWeight[language],
    totalWeight: strings.shippmentPage.modal.totalWeight[language],
    stockDays: (val: string | number) =>
      strings.shippmentPage.modal.stockDays.func(val)[language],
    stockPrice: strings.shippmentPage.modal.stockPrice[language],
    serviceTax: strings.shippmentPage.modal.serviceTax[language],
    taxRate: strings.shippmentPage.modal.taxRate[language],
    shippmentFee: strings.shippmentPage.modal.shippmentFee[language],
    creditAlert: strings.shippmentPage.modal.creditAlert[language],
    shipChoice: strings.shippmentPage.modal.shipChoice[language],
    address: strings.shippmentPage.modal.address[language],
    create: strings.buttons.create[language],
    shippmentID: strings.shippmentPage.modal.shippmentID[language],
    createdAt: strings.shippmentPage.cards.arrivedAt[language],
    shippedAt: strings.shippmentPage.cards.shipped[language],
    trackingNumber: strings.shippmentPage.modal.trackingNumber[language],
    edit: strings.buttons.edit[language],
    track: strings.shippmentPage.modal.track[language],
    oldProducts: strings.shippmentPage.modal.oldProducts[language],
    leftInStock: strings.shippmentPage.modal.leftInStock[language],
    kgWarming: strings.shippmentPage.modal.kgWarming[language],
    addProducts: strings.buttons.addPrds[language],
    shipBoxEditText: strings.shippmentPage.modal.shipBoxEditText[language],
    shipTotalPrice: strings.shippmentPage.modal.shipTotalPrice[language],
    remaingFee: strings.shippmentPage.modal.remaingFee[language],
    discount: strings.orderModal.discount[language],
    typeDiscount: strings.shippmentPage.modal.typeDiscount[language],
    credits: strings.resumeService.credits[language],
    paymentTax: strings.orderModal.paymentTax[language],
    confirmStatusShipped:
      strings.shippmentPage.modal.confirmStatusShipped[language],
    shippedBTN: strings.buttons.shipped[language],
    waitingPayment: strings.buttons.waitingPayment[language],
    confirmStatusWaitingPayment:
      strings.shippmentPage.modal.confirmStatusWaitingPayment[language],
    editDelivery: strings.shippmentPage.modal.editDelivery[language],
    editShipBTN: strings.buttons.editShip[language],
    cancelBTN: strings.buttons.cancel[language],
    cancelShip: strings.shippmentPage.modal.cancelShip[language],
    declaredValue: strings.shippmentPage.modal.declaredValue[language],
    declaredValueLabel:
      strings.shippmentPage.modal.declaredValueLabel[language],
    notDefined: strings.shippmentPage.modal.notDefined[language],
    jpyValue: strings.shippmentPage.modal.jpyValue[language],
    updateShip: strings.buttons.update[language],
    updateMessage: strings.shippmentPage.modal.editShip[language],
    deleteShip: strings.shippmentPage.modal.deleteShip[language],
    deleteShipBTN: strings.buttons.deleteShip[language],
  };

  return {
    labelStatus,
    orderModal,
    termsAndconditions,
    adminButtons,
    filter,
    orderTypeStrings,
    orderFilterOptions,
    userStatus,
    adminOrder,
    shippmentLabelStatus,
    addressStrings,
    shipCards,
    shippmentModal,
    auctionSteps,
    paymentChoices,
    pixDisabledMessage,
  };
}
