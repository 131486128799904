import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { BoxContent, BreakpointBox } from "../../Atoms";
import {
  EmailField,
  GenericInput,
  PasswordField,
  SimpleConfirmModal,
} from "../../Molecules";
import { useBreakpoints } from "../../../../hooks/useBreakpoins";
import treasureBoxLogo from "../../../../assets/images/NEWTREASUREBOXLOGO2.png";
import useAuth from "../../../../hooks/context/useAuth";
import { useTranslate } from "../../../../hooks/useTranslate";
import * as yup from "yup";
import useToast from "../../../../hooks/context/useToast";
import { useFormik } from "formik";
import CountrySelect from "../AutocompletePhone";
import { updateUserServiceAdmin } from "../../../../services/getUsersAdmin";
import { red } from "@mui/material/colors";
import { deleteUserService } from "../../../../services/updateUserService";
import { useState } from "react";

interface IFormInputs {
  age: string;
  name: string;
  phoneCode: string;
  phone: string;
  ssn: string;
  email: string;
  password: string;
  role: "client" | "admin";
  money: string;
  access: "permitted" | "blocked";
  canMakeOrders: string;
}

interface Props {
  data: UsersAdmin | null | undefined;
  setAttData: VoidFunction;
  closeModal: VoidFunction;
}

export function UpdateUserAdmin({ data, setAttData, closeModal }: Props) {
  const { isMobile } = useBreakpoints();
  const { strings } = useTranslate();
  const { language } = useAuth();
  const { setOpen, setToastData } = useToast();
  const [confirmModal, setConfirmModal] = useState(false);

  const validationSchema = yup.object({
    name: yup
      .string()
      .required(strings.validationFields.name.required[language]),
    phoneCode: yup
      .string()
      .required(strings.validationFields.phoneCode.required[language]),
    phone: yup
      .number()
      .typeError(strings.validationFields.phoneCode.typeError[language])
      .required(strings.validationFields.phoneCode.required[language]),
    ssn: yup
      .number()
      .typeError(strings.validationFields.phoneCode.typeError[language])
      .test((val) => {
        if (!val) return false;
        if (String(val).length > 15) return false;
        return true;
      })
      .required(strings.validationFields.ssn[language]),
    email: yup
      .string()
      .email(strings.validationFields.email[language])
      .required(strings.validationFields.email.required[language]),
    access: yup
      .string()
      .required(strings.validationFields.access.required[language]),
    role: yup
      .string()
      .required(strings.validationFields.role.required[language]),
    money: yup
      .string()
      .required(strings.validationFields.money.required[language]),
    canMakeOrders: yup
      .string()
      .required(strings.validationFields.access.required[language]),
  });

  const formik = useFormik<IFormInputs>({
    initialValues: {
      age: String(data?.user?.age) ?? "",
      email: data?.user?.email ?? "",
      name: data?.user?.name ?? "",
      phone: data?.user?.phone ?? "",
      phoneCode: data?.user.phoneCode ?? "",
      ssn: data?.user?.ssn ?? "",
      password: "",
      access: data?.user?.access ?? "permitted",
      money: data?.money ?? "",
      role: data?.user?.role ?? "client",
      canMakeOrders: String(data?.user?.canMakeOrders),
    },
    validationSchema,
    async onSubmit(values: any) {
      const updateUser = await updateUserServiceAdmin({
        ...values,
        canMakeOrders: values.canMakeOrders === "true" ? true : false,
        id: data?.user?.id,
      });

      if (updateUser?.message === "success") {
        setToastData({
          anchor: { vertical: "top", horizontal: "center" },
          duration: 5000,
          type: "success",
          message: strings.toastMessages.editPersonalData[language],
        });
        setOpen(true);
        setAttData();
        closeModal();
        setConfirmModal(false);
      } else {
        setToastData({
          anchor: { vertical: "top", horizontal: "center" },
          duration: 5000,
          type: "error",
          message: strings.toastMessages.editErrorPersonalData[language],
        });
        setOpen(true);
      }
    },
  });

  const deleteUser = async () => {
    try {
      if (data) {
        await deleteUserService(data?.user?.id);
        setToastData({
          anchor: { vertical: "top", horizontal: "center" },
          duration: 5000,
          type: "success",
          message: strings.toastMessages.deleteUser[language],
        });
        setOpen(true);
        closeModal();
        setAttData();
      }
    } catch {
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "error",
        message: strings.toastMessages.deleteUserError[language],
      });
      setOpen(true);
    }
  };

  return (
    <>
      <BoxContent config={{ p: 3 }}>
        <img src={treasureBoxLogo} width={150} alt="treasurebox logo" />
        <Typography variant="h1" textAlign={"left"} sx={{ width: "99%" }}>
          Suite: {data?.suite[0]?.id}
        </Typography>
        <BreakpointBox>
          <GenericInput
            hasIcon={false}
            onChange={(value, name) => formik.setFieldValue(name, value)}
            error={formik?.touched?.name && formik?.errors?.name ? true : false}
            errorMessage={formik?.touched?.name ? formik?.errors?.name : ""}
            value={formik?.values?.name}
            placeholder={strings.createUser.namePlaceholder[language]}
            inputName="name"
          />
          <GenericInput
            hasIcon={false}
            onChange={(value, name) => formik.setFieldValue(name, value)}
            error={formik?.touched?.age && formik?.errors?.age ? true : false}
            errorMessage={formik?.touched?.age ? formik?.errors?.age : ""}
            value={formik?.values?.age}
            placeholder={strings.createUser.age[language]}
            inputName="age"
            width={isMobile ? "100%" : "50%"}
          />
        </BreakpointBox>
        <BreakpointBox>
          <Box display="flex" sx={{ width: "100%" }}>
            <CountrySelect
              selectedCode={(val) => formik.setFieldValue("phoneCode", val)}
              defaultValue={formik?.values?.phoneCode}
            />
            <GenericInput
              hasIcon={false}
              onChange={(value, name) => formik.setFieldValue(name, value)}
              error={
                (formik?.touched?.phone && formik?.errors?.phone) ||
                (formik?.touched?.phoneCode && formik?.errors?.phoneCode)
                  ? true
                  : false
              }
              errorMessage={
                formik?.touched?.phone || formik?.touched?.phoneCode
                  ? formik?.errors?.phone
                    ? formik?.errors?.phone
                    : formik?.errors?.phoneCode
                    ? formik?.errors?.phoneCode
                    : ""
                  : ""
              }
              value={formik?.values?.phone}
              placeholder={strings.createUser.phonePlaceholder[language]}
              inputName="phone"
            />
          </Box>
          <GenericInput
            hasIcon={false}
            onChange={(value, name) => formik.setFieldValue(name, value)}
            error={formik?.touched?.ssn && formik?.errors?.ssn ? true : false}
            errorMessage={formik?.touched?.ssn ? formik?.errors?.ssn : ""}
            value={formik?.values?.ssn}
            placeholder={strings.createUser.ssn[language]}
            inputName="ssn"
          />
        </BreakpointBox>

        <EmailField
          hasIcon={false}
          onChange={(value) => formik.setFieldValue("email", value)}
          error={formik?.touched?.email && formik?.errors?.email ? true : false}
          errorMessage={formik?.touched?.email ? formik?.errors?.email : ""}
          value={formik?.values?.email}
          placeholder="E-mail"
        />
        <GenericInput
          hasIcon={false}
          onChange={(value, name) => formik.setFieldValue(name, value)}
          error={formik?.touched?.money && formik?.errors?.money ? true : false}
          errorMessage={formik?.touched?.money ? formik?.errors?.money : ""}
          value={formik?.values?.money}
          placeholder={strings.createUser.money[language]}
          inputName="money"
        />
        <PasswordField
          hasIcon={false}
          onChange={(value) => formik.setFieldValue("password", value)}
          error={false}
          value={formik?.values?.password}
          placeholder={strings.createUser.passwordPlaceholder[language]}
        />
        {/* <BreakpointBox props={{ mt: 3, gap: 3 }}> */}
        {/* <FormControl fullWidth>
            <InputLabel id="role-select">Role</InputLabel>
            <Select
              labelId="role-select"
              id="role-select"
              value={formik.values.role}
              label="Role"
              onChange={(e) => {
                formik.setFieldValue("role", e.target.value);
              }}
              error={
                formik?.touched?.role && formik?.errors?.role ? true : false
              }
            >
              <MenuItem value={"client"}>Client</MenuItem>
              <MenuItem value={"admin"}>Admin</MenuItem>
            </Select>
          </FormControl> */}
        <BreakpointBox props={{ sx: { gap: 2 } }}>
          <FormControl fullWidth>
            <InputLabel id="access-select">Access</InputLabel>
            <Select
              labelId="access-select"
              id="access-select"
              value={formik.values.access}
              label="Access"
              onChange={(e) => formik.setFieldValue("access", e.target.value)}
              error={
                formik?.touched?.access && formik?.errors?.access ? true : false
              }
            >
              <MenuItem value={"permitted"}>Permitted</MenuItem>
              <MenuItem value={"blocked"}>Blocked</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel id="orders-select">Can make orders</InputLabel>
            <Select
              labelId="orders-select"
              id="orders-select"
              value={formik.values.canMakeOrders}
              label="Can make orders"
              onChange={(e) =>
                formik.setFieldValue("canMakeOrders", e.target.value)
              }
              error={
                formik?.touched?.canMakeOrders && formik?.errors?.canMakeOrders
                  ? true
                  : false
              }
            >
              <MenuItem value={"true"}>Yes</MenuItem>
              <MenuItem value={"false"}>No</MenuItem>
            </Select>
          </FormControl>
        </BreakpointBox>
        {/* </BreakpointBox> */}
        <BreakpointBox props={{ justifyContent: "space-between" }}>
          <Button
            sx={{ mt: 3, width: isMobile ? "100%" : "48%" }}
            variant="contained"
            onClick={() => formik.handleSubmit()}
          >
            {strings.buttons.edit[language]}
          </Button>
          <Button
            sx={{
              mt: 3,
              width: isMobile ? "100%" : "48%",
              bgcolor: red[600],
              "&:hover": { bgcolor: red[200] },
            }}
            variant="contained"
            onClick={closeModal}
          >
            {strings.buttons.cancelBtn[language]}
          </Button>
        </BreakpointBox>
        <Button
          sx={{
            mt: 3,
            bgcolor: red[600],
            "&:hover": { bgcolor: red[200] },
          }}
          variant="contained"
          fullWidth
          onClick={() => setConfirmModal(true)}
        >
          {strings.buttons.deleteUer[language]}
        </Button>
      </BoxContent>

      <SimpleConfirmModal
        cancelClick={() => setConfirmModal(false)}
        confirmClick={deleteUser}
        open={confirmModal}
        text={strings.usersPage.deleteUser[language]}
      />
    </>
  );
}
