import {
  CardActions,
  CardContent,
  Card,
  Box,
  Chip,
  Typography,
} from "@mui/material";
import { DataText, Button, NoData, CircularLoading } from "../../Atoms";
import { useBreakpoints } from "../../../../hooks/useBreakpoins";
import { useCommonData } from "../../../../hooks/useCommonData";
import { useJPYmoney } from "../../../../hooks/useJPYmoney";

interface Props {
  data: UsersAdmin[];
  onClickUser(val: UsersAdmin): void;
  isLoading?: boolean;
}

export function UsersCards({ data, onClickUser, isLoading }: Props) {
  const { labelStatus, userStatus } = useCommonData();
  const { isSmalldesktop, isMobile, mediumDesktop, semiSmalldesktop } =
    useBreakpoints();
  const { JPYmoney } = useJPYmoney();

  const titleWidth = 100;

  const breakpoints = () => {
    if (isMobile) return "100%";
    if (isSmalldesktop) return "48.5%";
    if (semiSmalldesktop) return "32%";
    if (mediumDesktop) return "23.9%";
    return "19%";
  };

  return (
    <Box
      width={"auto"}
      display="flex"
      flexWrap={"wrap"}
      rowGap={1.5}
      columnGap={1.5}
      justifyContent={"flex-start"}
    >
      {isLoading ? (
        <CircularLoading />
      ) : data && data?.length > 0 ? (
        data.map((item, index) => (
          <Card
            elevation={3}
            sx={{
              width: breakpoints(),
              minWidth: "245px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              // bgcolor: cardColor(item.orderType),
            }}
            key={index}
          >
            <Box>
              <Typography p={1} variant="h2" fontWeight={500}>
                {item?.user?.name}
              </Typography>
            </Box>
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  justifyContent: "center",
                  rowGap: 0.5,
                }}
              >
                <DataText
                  title={"ID:"}
                  text={item?.user?.id}
                  titleProps={{ width: titleWidth }}
                />
                <DataText
                  title={"Suite:"}
                  text={item?.suite[0]?.id}
                  titleProps={{ width: titleWidth }}
                />
                <DataText
                  title={"E-mail:"}
                  text={`${item?.user?.email}`}
                  titleProps={{ width: titleWidth }}
                />
                <DataText
                  title={"Money:"}
                  text={`${JPYmoney(Number(item?.money))}`}
                  titleProps={{ width: titleWidth }}
                />
                <DataText
                  title={"Phone:"}
                  text={`${item?.user?.phone}`}
                  titleProps={{ width: titleWidth }}
                />
                <DataText
                  title={"SSN:"}
                  text={`${item?.user?.ssn}`}
                  titleProps={{ width: titleWidth }}
                />
                <DataText
                  title={"Role:"}
                  text={`${item?.user?.role}`}
                  titleProps={{ width: titleWidth }}
                />
                <DataText
                  title={"Can buy:"}
                  text={`${item?.user?.canMakeOrders ? "Yes" : "No"}`}
                  titleProps={{ width: titleWidth }}
                />
              </Box>
            </CardContent>
            <CardActions
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Button
                config={{
                  size: "small",
                  fullWidth: false,
                  onClick: () => onClickUser(item),
                }}
                style={{ color: "white" }}
              >
                {labelStatus.button.label}
              </Button>

              <Chip
                label={
                  userStatus[item?.user?.access as keyof typeof userStatus]
                    ?.label
                }
                style={{
                  background:
                    userStatus[item?.user?.access as keyof typeof userStatus]
                      ?.color,
                  color: "white",
                  fontWeight: 500,
                }}
              />
            </CardActions>
          </Card>
        ))
      ) : (
        <NoData text="No data" />
      )}
    </Box>
  );
}
